import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import {
  type BusinessStructure,
  type BusinessType,
  type CheckStatus,
  type EntityStatus,
  type TrustType,
  type UserRole,
  type UserStatus,
} from '@/libs/enum';

export type UserAccount = {
  id: string;
  name: string;
  provisioned: boolean;
};

export type BackgroundCheck = {
  status: CheckStatus;
  failReason?: string;
};

export type KybCheckStatus = {
  checkStatus: CheckStatus;
};

export type KycCheckStatus = {
  checkStatus: CheckStatus;
  comments?: string;
  failReason?: string;
  lastCommentedBy?: string;
};

export type UserAccountCheck = {
  kybStatus?: KybCheckStatus;
  kycStatus?: KycCheckStatus;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  mobile: string;
  isMobileVerified: boolean;
  providingBuildingService: boolean;
  userStatus: UserStatus;
  homeAddress?: string;
  businessName?: string;
  businessAddress?: string;
  businessSetupCompleted?: boolean;
  trustName?: string;
  trustAddress?: string;
  backgroundCheck?: UserAccountCheck;
  account?: UserAccount;
  createdAt: string;
  status: EntityStatus;
};

export type UserBusiness = {
  id: string;
  businessStructure: BusinessStructure;
  businessType: BusinessType;
  registeredName: string;
  registeredAddress: Address;
  abn: string;
  acn?: string;
  builderLicenceNumber: string;
  earlyReleaseDiscountRate?: number;
  registeredForGst: boolean;
  provideBuildingServices: boolean;
  businessSetupCompleted: boolean;
  backgroundCheck: BackgroundCheck;
  account?: UserAccount;
  officers?: Omit<UserDetail, 'account' | 'business'>;
  trustees?: UserDetail[];
  trustType?: TrustType;
};

export type UserTrustee = {
  structure: TrustType;
  acn?: string;
  officers: UserDetail[];
};

export type UserDetail = {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  isEmailVerified: boolean;
  mobile: string;
  isMobileVerified: boolean;
  backgroundCheck: BackgroundCheck;
  role: UserRole;
  homeAddress: Address;
  account?: UserAccount;
  business?: UserBusiness;
};

export type UserSummary = {
  id: string;
  fullName: string;
  displayName: string;
};

const initialState = {
  user: null as UserDetail | null,
  loading: false,
  forceUpdate: 0,
};

export const userManager = proxy(initialState);

devtools(userManager, {
  name: 'Current User',
  enabled: false,
});

export const resetUserProfile = () => {
  Object.assign(userManager, initialState);
};

export const saveUserProfile = (payload: UserDetail) => {
  const { user } = userManager;
  userManager.user = { ...user, ...payload };
};

export const reloadUserProfile = () => {
  const { user, forceUpdate } = userManager;
  if (!user) return;
  userManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};

export const toggleLoading = (payload: boolean) => {
  userManager.loading = payload;
};
